import React from 'react';
import './Footer.css';

const Footer = (): JSX.Element => {
  return (
    <footer className="App-footer">
      <div className="Footer">© Copyright and database rights The Stepstone Group UK Ltd {new Date().getFullYear()}</div>
      <div className="extra-div"></div>
    </footer>
  );
};

export default Footer;
