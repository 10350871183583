import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Entry from './screens/Entry';
import Status from './screens/Status';
import Configuration from './configuration';

const App = (): JSX.Element => {
  const [checkoutId, setCheckoutId] = useState('');

  const getConfig = async () => {
    const response = await fetch(Configuration.apiPrefix + '/api/config', {
      method: 'GET',
    });
    const responseBody = await response.json();
    Configuration.paymentUrl = responseBody.paymentUrl;
    Configuration.statusRedirectUrl = window.location.origin + '/status'; //responseBody.statusRedirectUrl;

    return response;
  };

  useEffect(() => {
    (async () => {
      await getConfig();
    })();
  });

  return (
    <Router>
      <div className="App">
        <Header />
        <div className="Main">
          <div className="Main-content">
            <Switch>
              <Route path="/status">
                <Status />
              </Route>
              <Route path="/">
                <Entry checkoutId={checkoutId} setCheckoutId={setCheckoutId} />
              </Route>
            </Switch>
          </div>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
