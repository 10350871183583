import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Form from '../components/Form';
import Checkout from '../components/Checkout';

const Entry: React.FC<EntryProps> = ({ checkoutId, setCheckoutId }): JSX.Element => {
  const [formSuccess, setFormSuccess] = useState(false);

  return (
    <>
      {!formSuccess ? (
        <>
          <div className="Welcome">Welcome</div>
          <Form setFormSuccess={setFormSuccess} setCheckoutId={setCheckoutId} />
        </>
      ) : (
        <>
          <div className="Welcome">Please enter your card details below</div>
          <Checkout id={checkoutId} />
        </>
      )}
    </>
  );
};

export default Entry;

const propTypes = {
  checkoutId: PropTypes.string.isRequired,
  setCheckoutId: PropTypes.func.isRequired,
};
Entry.propTypes = propTypes;

type EntryProps = PropTypes.InferProps<typeof propTypes>;
