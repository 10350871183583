import React from 'react';
import { Link } from 'react-router-dom';

import './Header.css';

const Header = (): JSX.Element => {
  return (
    <header className="App-header">
      <div className="Header">
        <Link to="/">
          <img src="stst-logo.png" className="Logo" alt="The StepStone Group logo." />
        </Link>
        <h1 className="Title">The Stepstone Group UK – Invoice Payment Service</h1>
      </div>
    </header>
  );
};

export default Header;
