import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import Configuration from '../configuration';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './Form.css';

type InvoiceForm = {
  companyName: string;
  invoiceNumber: string;
  invoiceAmount: string;
  phoneNumber: string;
  email: string;
};

type FormError = {
  error: boolean;
  message: string;
};

const getCheckoutId = async (values: InvoiceForm) => {
  const response = await fetch(Configuration.apiPrefix + '/api/Invoice/checkout', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      companyName: values.companyName,
      invoiceNumber: values.invoiceNumber,
      amount: Number(values.invoiceAmount),
      phoneNumber: values.phoneNumber,
      email: values.email,
    }),
  });

  return response;
};

const validate = (invoiceFormValues: InvoiceForm): Record<string, FormError> => {
  return {
    companyName: {
      error: invoiceFormValues.companyName.length === 0,
      message: 'Cannot be empty',
    },
    invoiceNumber: {
      error: validateInvoiceNumber(invoiceFormValues.invoiceNumber),
      message: 'Must be a valid invoice number (e.g. 80000000 or PRO12345678)',
    },
    invoiceAmount: {
      error: Number(invoiceFormValues.invoiceAmount) <= 0,
      message: 'Cannot be zero or negative',
    },
    phoneNumber: {
      error: validatePhoneNumber(invoiceFormValues.phoneNumber),
      message: 'Must be a valid phone number',
    },
    email: {
      error: validateEmail(invoiceFormValues.email),
      message: 'Please use a valid email address',
    },
  };
};

const validatePhoneNumber = (phoneNumber: string) => {
  const re = /^[0-9]{8,20}$/;
  return !re.test(String(phoneNumber).toLowerCase());
};

const validateInvoiceNumber = (invoiceNumber: string) => {
  const re = /^(?:(?:pro[0-9])|8)[0-9]{7,8}$/;
  return !re.test(String(invoiceNumber).toLowerCase());
};

const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !re.test(String(email).toLowerCase());
};

const Form: React.FC<FormProps> = ({ setFormSuccess, setCheckoutId }): JSX.Element => {
  const [invoiceFormValues, setInvoiceFormValues] = useState({
    companyName: '',
    invoiceNumber: '',
    invoiceAmount: '',
    phoneNumber: '',
    email: '',
  });

  const [touchedFormValues, setTouchedFormValues]: [Record<string, boolean>, any] = useState({
    companyName: false,
    invoiceNumber: false,
    invoiceAmount: false,
    phoneNumber: false,
    email: false,
  });

  const [formError, setFormError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const errors = validate(invoiceFormValues);
  const isInvalid = Object.values(errors).some((error) => error.error);

  const toggleIsLoading = (): void => {
    setIsLoading((prevIsLoading) => !prevIsLoading);
  };

  const shouldMarkError = (field: string): boolean => {
    const hasError = errors[field].error;
    const shouldShow = touchedFormValues[field];

    return hasError ? shouldShow : false;
  };

  const getErrorMessage = (field: string): string => {
    return shouldMarkError(field) ? errors[field].message : '';
  };

  const handleChange = (event: any): void => {
    setInvoiceFormValues({
      ...invoiceFormValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleBlur = (event: any): void => {
    setTouchedFormValues({
      ...touchedFormValues,
      [event.target.name]: true,
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setFormError(false);
    toggleIsLoading();

    try {
      const response = await getCheckoutId(invoiceFormValues);

      if (response.status === 200) {
        const data = await response.json();
        setCheckoutId(data.id);
        setFormSuccess(true);
        setInvoiceFormValues({
          companyName: '',
          invoiceNumber: '',
          invoiceAmount: '',
          phoneNumber: '',
          email: '',
        });
        setTouchedFormValues({
          companyName: false,
          invoiceNumber: false,
          invoiceAmount: false,
          phoneNumber: false,
          email: false,
        });
      } else {
        setFormError(true);
      }
    } catch (err) {
      setFormError(true);
    }

    toggleIsLoading();
  };

  return (
    <div className="Form">
      <div className="Form-header">Please complete the fields below to pay your invoice</div>
      <div>All of the following fields are required.</div>
      <form className="Form-inputs" onSubmit={handleSubmit} onBlur={handleBlur}>
        <div className="Form-input">
          <label htmlFor="companyName">Company name:</label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            onChange={handleChange}
            value={invoiceFormValues.companyName}
            className={shouldMarkError('companyName') ? 'Input-error' : ''}
          />
          <div className="Error">{getErrorMessage('companyName')}</div>
        </div>
        <div className="Form-input">
          <label htmlFor="invoiceNumber">Invoice number:</label>
          <input
            type="text"
            id="invoiceNumber"
            name="invoiceNumber"
            onChange={handleChange}
            value={invoiceFormValues.invoiceNumber}
            className={shouldMarkError('invoiceNumber') ? 'Input-error' : ''}
          />
          <div className="Error">{getErrorMessage('invoiceNumber')}</div>
        </div>
        <div className="Form-input">
          <label htmlFor="invoiceAmount">Total invoice amount (£):</label>
          <input
            type="number"
            id="invoiceAmount"
            name="invoiceAmount"
            onChange={handleChange}
            value={invoiceFormValues.invoiceAmount}
            className={shouldMarkError('invoiceAmount') ? 'Input-error' : ''}
          />
          <div className="Error">{getErrorMessage('invoiceAmount')}</div>
        </div>
        <div className="Form-input">
          <label htmlFor="phoneNumber">Contact number:</label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            onChange={handleChange}
            value={invoiceFormValues.phoneNumber}
            className={shouldMarkError('phoneNumber') ? 'Input-error' : ''}
          />
          <div className="Error">{getErrorMessage('phoneNumber')}</div>
        </div>
        <div className="Form-input">
          <label htmlFor="email">Email:</label>
          <input
            type="text"
            id="email"
            name="email"
            onChange={handleChange}
            value={invoiceFormValues.email}
            className={shouldMarkError('email') ? 'Input-error' : ''}
          />
          <div className="Error">{getErrorMessage('email')}</div>
        </div>
        <div className="Form-submit">
          <button name="submit" disabled={isInvalid || (!isInvalid && isLoading)}>
            {isLoading ? <Loader type="TailSpin" color="white" height={16} /> : <>Proceed to payment</>}
          </button>
          <div className="Error">
            {formError
              ? 'Sorry, there was an error submitting your details. Please try again later or contact us if the error persists.'
              : ''}
          </div>
        </div>
      </form>
      <div>
        If you do not know your invoice number or would like to set up a direct debit for future invoice payments,
        please call us on 0333 0145 175 or email <a href="mailto:UK_AR@thestepstonegroup.com">UK_AR@thestepstonegroup.com</a>.
      </div>
    </div>
  );
};

export default Form;

const propTypes = {
  setFormSuccess: PropTypes.func.isRequired,
  setCheckoutId: PropTypes.func.isRequired,
};

Form.propTypes = propTypes;

type FormProps = PropTypes.InferProps<typeof propTypes>;
