import React, { useState, useEffect } from 'react';
import Configuration from '../configuration';
import PropTypes from 'prop-types';

const loadExternalScript = (id: string, callback: any) => {
  const existingScript = document.getElementById('total-processing');
  if (!existingScript && id !== '') {
    const script = document.createElement('script');
    script.src = `${Configuration.paymentUrl}/v1/paymentWidgets.js?checkoutId=` + id;
    script.id = 'total-processing';
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };

    if (existingScript && callback) callback();
  }
};

const Checkout: React.FC<CheckoutProps> = ({ id }): JSX.Element => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loadExternalScript(id, () => {
      setLoaded(true);
    });
  });

  return (
    <div className="Checkout">
      {loaded ? (
        <form action={Configuration.statusRedirectUrl} className="paymentWidgets" data-brands="VISA MASTER AMEX"></form>
      ) : (
        ''
      )}
    </div>
  );
};

export default Checkout;

const propTypes = {
  id: PropTypes.string.isRequired,
};
Checkout.propTypes = propTypes;

type CheckoutProps = PropTypes.InferProps<typeof propTypes>;
