import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { Link, useLocation } from 'react-router-dom';
import Configuration from '../configuration';
import Loader from 'react-loader-spinner';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import './Status.css';
import { ITotalProcessingResponse } from '../Models/ITotalProcessingResponse';

const Status = (): JSX.Element => {
  const { search } = useLocation();
  const checkoutId = queryString.parse(search).id;

  const [checkoutSuccess, setCheckoutSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [merchantTransactionId, setTransactionId] = useState('');

  const isResponseCodeOk = (code: string) => {
    const regex = /^(000\.000\.|000\.100\.1|000\.[36])/g;
    const match = code.match(regex);
    return match !== null;
  };

  useEffect(() => {
    fetch(Configuration.apiPrefix + `/api/Invoice/status?checkoutId=${checkoutId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((statusResponse) => {
        statusResponse.json().then((jsonResponse: ITotalProcessingResponse) => {
          setCheckoutSuccess(statusResponse.status === 200 && isResponseCodeOk(jsonResponse.result.code));
          setTransactionId(jsonResponse.merchantTransactionId);
        });
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className="Status">
      <div className="Status-result">
        {checkoutSuccess && !isLoading ? (
          <>
            <div className="Status-heading">Thank you, your payment has been successful.</div>
            <p>
              Your payment reference number is <b>{merchantTransactionId}</b>.
            </p>
            <p>
              If you have any questions, please call 0333 0145 175 or email{' '}
              <a href="mailto:UK_AR@thestepstonegroup.com">UK_AR@thestepstonegroup.com</a>.
            </p>
            <p>
              Click <Link to="/">here</Link> to pay another invoice.
            </p>
          </>
        ) : isLoading ? (
          <Loader type="TailSpin" color="black" />
        ) : (
          <>
            <div className="Status-heading">Your checkout was unsuccessful.</div>
            <p>
              Please call us on 0333 0145 175 or email <a href="mailto:UK_AR@thestepstonegroup.com">UK_AR@thestepstonegroup.com</a>{' '}
              for assistance, providing the following checkout reference ID: <b>{checkoutId}</b>.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Status;
